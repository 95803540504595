//
// Mixins
// --------------------------------------------------


// Font smoothing

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Social buttons hover

@mixin btn-social-hover($color) {
  &:hover {
    border-color: rgba($color, .3);
    color: $color;
  }
}

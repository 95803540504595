//
// List group
// --------------------------------------------------


// Disable bottom margin on list group items

.list-group-item { margin-bottom: 0;}


// Interactive list items

.list-group-item-action {
  transition: $list-group-action-transition;
  &:active {
    transition: all;
  }
}


// Contextual variants

@each $color, $value in $theme-colors {
  .list-group-item-action.list-group-item-#{$color} {
    &:hover, &:active, &:focus {
      background-color: #282939 !important;
    }
  }
  .list-group-item-#{$color}.active {
    background-color: darken($value, 10%) !important;
    border-color: $value;
    box-shadow: none;
  }
}

.list-group-item-secondary,
.list-group-item-light {
  color: $gray-800 !important;
}
.list-group-item-secondary {
  background-color: $gray-100 !important;
}
.list-group-item-light {
  background-color: $light !important;
}
.list-group-item-dark {
  background-color: $dark !important;
  color: $white !important;
}
